<template>
  <div>
    <h2>Error</h2>

    <p>{{ message }}</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        message: null,
      };
    },
    mounted() {
      if (this.$route.params.message) {
        this.message = this.$route.params.message;
      }

      //Return user to previous page after 5 seconds
    },
  };
</script>
